import React from 'react';

const HomePage = React.lazy(() => import('./views/Home/HomePage'));
const DistributionPointsPage = React.lazy(() => import('./views/Locations/DistributionPointsPage'));
const CustomersManagementPage = React.lazy(() =>
  import('./views/Customers/CustomersManagementPage')
);
const ManageQrCodesPage = React.lazy(() => import('./views/ManageQrCodes/ManageQrCodesPage'));
const PrintQrCodesPage = React.lazy(() => import('./views/ManageQrCodes/PrintQrCodesPage'));
const CollectionPointsPage = React.lazy(() => import('./views/Locations/CollectionCentersPage'));
const LocationDetailsPage = React.lazy(() => import('./views/Locations/LocationDetailsPage'));
const Transactions = React.lazy(() => import('./views/Transactions/TransactionsPage'));
const UsersManagementPage = React.lazy(() => import('./views/UsersPage/UsersManagementPage'));
const ReportPage = React.lazy(() => import('./views/Reports/ReportsPage'));
const TenantsPage = React.lazy(() => import('./views/Tenants/TenantsPage'));
const QRCodeSummary = React.lazy(() => import('./views/Summary/QRCodeSummaryManagement'));
const TenantManagement = React.lazy(() => import('./views/Tenant/TenantsListTable'));
const RvmDashboardPage = React.lazy(() => import('./views/RvmDashboard/RvmDashboardPage'));

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    exact: true,
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER'],
  },
  {
    path: '/',
    name: 'Tenants',
    exact: true,
    component: TenantManagement,
    authorities: ['ROLE_PLATFORM_ADMIN'],
  },
  {
    path: '/customers',
    name: 'Customers',
    component: CustomersManagementPage,
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER'],
  },
  {
    name: 'Location Details',
    path: '/distribution-points/view/:locationId',
    component: LocationDetailsPage,
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER'],
  },
  {
    path: '/distribution-points',
    name: 'Distribution Points',
    component: DistributionPointsPage,
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER'],
  },
  {
    path: '/manage-qr-codes',
    name: 'Manage QR Codes',
    component: ManageQrCodesPage,
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER'],
  },
  {
    path: '/print-qr-codes',
    name: 'Print QR Codes',
    component: PrintQrCodesPage,
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER'],
  },
  {
    name: 'Location Details',
    path: '/collection-points/view/:locationId',
    component: LocationDetailsPage,
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER'],
  },
  {
    path: '/collection-points',
    name: 'Collection Centers',
    component: CollectionPointsPage,
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER'],
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: Transactions,
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER'],
  },
  {
    path: '/users',
    name: 'User',
    component: UsersManagementPage,
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER'],
  },
  {
    path: '/reports',
    name: 'Reports',
    component: ReportPage,
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER'],
  },
  {
    path: '/qr-history',
    name: 'Lot and QR History',
    component: QRCodeSummary,
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER'],
  },
  {
    path: '/tenant',
    name: 'Tenant',
    component: TenantManagement,
    authorities: ['ROLE_ADMIN', 'ROLE_RECYKAL_USER'],
  },
  {
    path: '/RVM',
    name: 'RVM Page',
    component: RvmDashboardPage,
    authorities: ['ROLE_ADMIN'],
  },
];

export default routes;
