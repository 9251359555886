const ApiEndpoints = {
  AUTH: {
    LOGIN: '/authenticate',
    REQUEST_RESET_PASSWORD: '/account/reset_password/init',
    CREATE_PASSWORD: '/account/reset_password/finish',
    GOOGLE_LOGIN: '/googlelogin',
    VAILDATE_TOKEN: '/validate-token',
  },

  USER: {
    ACCOUNT: '/account',
    CHANGE_PASSWORD: '/account/change-password',
  },

  USERS: {
    FETCH: '/admin/users',
    CREATE: '/admin/users',
    UPDATE: '/admin/users',
  },

  CUSTOMERS: {
    FETCH: '/shops/list',
    CREATE: '/shops/create',
    UPDATE: '/shops/update',
    DOWNLOAD: '/shops/download-list',
  },

  LOCATIONS: {
    FETCH_ALL: '/all-locations',
    FETCH: '/locations',
    CREATE: '/locations',
    UPDATE: '/locations',
    GROUND_DETAILS: '/location-ground-details',
  },

  QR_CODES: {
    PRINT: '/print-qr-codes',
    DOWNLOAD: '/download-qr-codes',
    ASSIGN: '/issue-to-dp',
    FETCH_LOTS: '/lots',
    CONTAINER_TYPE: '/container-types',
    PRINT_BULK: '/qr/download-qr-codes-csv-file',
  },

  TRANSACTIONS: {
    FETCH_TRANSACTIONS: '/txns',
    ADD_UNUSED_TXN: '/un-scaned-txn',
  },

  DASHBOARD: {
    FETCH_STATS: '/dashboard-stats',
    FETCH_QR_CODE_STATS: '/dashboard-qrcode-stats',
    COLLECTION_SUMMERY: '/dashboard-collection-summary',
  },

  REPORT: {
    SHOP_TXN: '/shop-txn-report',
    DISTRIBUTION_TXN: '/distribution-txns',
    COLLECTION_TXN: '/collection-txns',
    RETURN_UNUSED: '/return-unused-txns',
    OFFLINE_TXN: '/offline-collection-txns',
  },

  TENANTS: {
    FETCH_ALL: '/tenants',
    CREATE: '/tenants',
    UPDATE: '/tenants/update',
  },

  SUMMARY: {
    DROP_REQUEST: '/drop-requests',
    QR_TXN: '/qr-txn-history',
    QR_CODE: '/qr-codes',
  },

  RVM_DASHBOARD: {
    FETCH_STATS: '/rvm-dashboard',
    FETCH_TXNS: '/rvm-recent-txns',
  },
};

export default ApiEndpoints;
